.row {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 4px;
  margin-bottom: 4px;
}

@media (min-width: 640px) {
  .row {
    gap: 6px;
    margin-bottom: 6px;
  }
}

@media (min-width: 940px) {
  .row {
    gap: 8px;
    margin-bottom: 8px;
  }
}

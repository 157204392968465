.wrapper {
  width: 100%;
}

@media (min-width: 640px) {
  .wrapper {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
}

.profile-image {
  position: relative;
  width: 100%;
  padding-top: 100%;
  overflow: hidden;
  border-radius: 16px;
}

.profile-image__img {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  object-position: center;
}

@media (min-width: 640px) {
  .profile-image {
    grid-column: span 3 / span 3;
  }
}

.profile-details {
  width: 100%;
  grid-template-rows: minmax(0, 1fr) auto;
  overflow: hidden;
  margin-top: 16px;
}

@media (min-width: 640px) {
  .profile-details {
    grid-column: span 5 / span 5;
    margin-top: 0;
  }
}

.displayname {
  font-size: 22px;
  font-weight: 500;
  margin: 0;
  color: var(--primary-950);
  line-height: 1.3;
}

.username {
  font-weight: 500;
  color: var(--primary-500);
  font-size: 16px;
  line-height: 1.3;
  padding: 0;
}

.bio {
  padding-top: 10px;
  padding-bottom: 10px;
  color: var(--primary-500);
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.wrapper {
  display: block;
  width: 100%;
  position: relative;
  z-index: 1;
  overflow: hidden;
  border-radius: 12px;
  background-color: #000;
}

.cover {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  object-position: center;
  z-index: 2;
  opacity: 1;
}

.child {
  z-index: 3;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 4;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
}

.wrapper:hover .overlay,
.overlay--active {
  transition: 0.5s ease;
  opacity: 1;
}

.divider {
  border: 0;
  border-top: 1px solid var(--primary-100);
  margin: 16px 0;
}

.section-heading {
  display: block;
  font-weight: 500;
  margin: 0 0 8px 0;
  color: var(--primary-950);
  font-size: 17px;
}

a.section-heading:hover {
  color: var(--primary-700);
}

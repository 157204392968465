.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  flex-direction: column;
  container-type: inline-size;
  opacity: 1;
  padding: 8px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.title {
  color: white;
  font-weight: 400;
  font-size: 14cqw;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 9;
  -webkit-box-orient: vertical;

  min-width: 0;
  overflow-wrap: break-word; /* required to break long words, especially at smaller container sizes */
}

.header {
  display: block;
  width: 100%;
  position: relative;
  z-index: 1;
  padding-top: 50%;
  overflow: hidden;
  background-color: #cacaca;
}

.header__bg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  object-position: center;
  z-index: 2;
}

.header__content {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  padding: 96px 16px 16px 16px;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0) 75%
  );
}

.profile-Link {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}

.profile-Link__sub-text {
  font-weight: 300;
}

.content {
  padding: 16px 0;
}

.content__heading {
  font-size: 22px;
  font-weight: 500;
  margin: 0 0 8px 0;
  color: var(--primary-950);
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
  padding: 16px 0;
}

.btn_round {
  border-radius: 48px;
  color: var(--white);
  background-color: var(--pink-600) !important;
  padding: 10px 20px;
}
.btn_round:hover {
  background-color: var(--pink-800) !important;
}

.section-heading {
  font-weight: 500;
  margin: 0 0 8px 0;
  color: var(--primary-950);
}

.latest {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 4px;
  margin-bottom: 4px;
}

@media (min-width: 640px) {
  .latest {
    gap: 6px;
    margin-bottom: 6px;
  }
}

@media (min-width: 940px) {
  .latest {
    gap: 8px;
    margin-bottom: 8px;
  }
}

.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  flex-direction: column;
  container-type: inline-size;
  opacity: 1;
  padding: 8px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  backdrop-filter: blur(30px);
}

.overlay--detail {
  backdrop-filter: blur(60px);
  padding: 24px;
}

.title {
  color: white;
  font-weight: 400;
  font-size: 11cqw;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 9;
  -webkit-box-orient: vertical;

  min-width: 0;
  overflow-wrap: break-word; /* required to break long words, especially at smaller container sizes */
}

.title--detail {
  font-size: 6.5cqw;
  -webkit-line-clamp: 20;
}

.lock {
  margin-top: 1rem;
}

.details {
  color: #fff;
  font-size: 4cqw;
  text-align: center;
  padding-top: 10cqw;
  font-weight: 400;
  line-height: 1.3333;
}

.details__primary {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
}

.details__secondary {
  font-weight: 300;
  margin-top: 1rem;
}

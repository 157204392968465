.wrapper {
  background-color: var(--mauve-500);
}

.container {
  max-width: 972px;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--white);
  min-height: 100vh;
  padding: 16px;
  padding-bottom: calc(80px + 16px); /* footer height + padding */
}

@media (min-width: 940px) {
  .container {
    padding: 32px 32px 141px 32px; /* footer height + padding */
  }
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 10;
}

.footer__content {
  color: var(--primary-300) !important;
  background-color: rgba(24, 24, 24, 0.9);
  padding: 16px;
  max-width: 972px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.footer__brand {
  display: flex;
  align-items: center;
}

.footer__links {
  display: flex;
  align-items: center;
  gap: 16px;
}

/* MENU */

.toggler {
  /* ALWAYS KEEPING THE TOGGLER OR THE CHECKBOX ON TOP OF EVERYTHING :  */
  z-index: 22;
  height: 72px;
  width: 72px;
  position: fixed;
  top: 0;
  right: 0;
  cursor: pointer;
  opacity: 0;
}

.hamburger {
  z-index: 21;
  position: fixed;
  top: 16px;
  right: 16px;
  height: 40px;
  width: 40px;
  padding: 0;
  background-color: rgba(93, 89, 143, 0.4);
  border-radius: 4px;

  /* FOR DISPLAYING EVERY ELEMENT IN THE CENTER : */

  display: flex;
  align-items: center;
  justify-content: center;
}

/* CREATING THE MIDDLE LINE OF THE HAMBURGER : */

.hamburger > div {
  position: relative;
  top: 0;
  left: 0;
  background: var(--white);
  height: 2px;
  width: 60%;
  transition: all 0.4s ease;
}

/* CREATING THE TOP AND BOTTOM LINES :
TOP AT -10PX ABOVE THE MIDDLE ONE AND BOTTOM ONE IS 10PX BELOW THE MIDDLE: */

.hamburger > div::before,
.hamburger > div::after {
  content: '';
  position: absolute;
  top: -10px;
  background: var(--white);
  width: 100%;
  height: 2px;
  transition: all 0.4s ease;
}

.hamburger > div::after {
  top: 10px;
}

/* IF THE TOGGLER IS IN ITS CHECKED STATE, THEN SETTING THE BACKGROUND OF THE MIDDLE LAYER TO TRANSPARENT :  */

.toggler:checked + .hamburger > div {
  background: rgba(255, 255, 255, 0);
}

.toggler:checked + .hamburger > div::before {
  top: 0;
  transform: rotate(45deg);
  /*    background: var(--black);*/
}

/* AND ROTATING THE TOP AND BOTTOM LINES :  */

.toggler:checked + .hamburger > div::after {
  top: 0;
  transform: rotate(135deg);
  /*    background: var(--black);*/
}

/* MAIN MENU WITH THE Dark BACKGROUND AND THE TEXT :  */

.menu {
  z-index: 20;
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  width: 0%;
  height: 100%;
  margin: 0;
  padding: 80px 0;
  list-style: none;
  background-color: var(--mauve-800);
  transition: all 0.4s ease;
}

/* IF THE TOGGLER IS CHECKED, THEN INCREASE THE WIDTH OF THE MENU TO 30% , CREATING A SMOOTH EFFECT :  */

.toggler:checked ~ .menu {
  width: 300px;
}

/* STYLING THE LIST :  */

.menu > div > ul {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 70px;
  padding-left: 10px;
  margin: 1rem 0;

  /* HIDDEN INITIALLY  :  */

  visibility: hidden;
}

.menu > div > ul > li {
  list-style: none;
  padding: 0.5rem;
}

.menu > div > ul > li > a {
  color: var(--gold);
  text-decoration: none;
  font-weight: 300;
  /*    font-size: 2rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
*/
}

.menu__item {
  font-size: 20px;
}

.menu__item-sm {
  font-size: 13px;
}

/* WHEN THE TOGGLER IS CHECKED, CHANGE THE VISIBILITY TO VISIBLE :  */

.toggler:checked ~ .menu > div > ul {
  transition: visibility 0.4s ease;
  transition-delay: 0.1s;
  visibility: visible;
}

.toggler:checked ~ .menu > div > ul > li > a:hover {
  color: var(--white);
}

.post-text {
  display: grid;
  place-content: center;
  text-align: center;
  font-weight: 400;
  color: var(--white);
  line-height: 1.3;
  container-type: inline-size;
  padding: 8px;
}

@media (min-width: 640px) {
  .post-text {
    padding: 12px;
  }
}

@media (min-width: 940px) {
  .post-text {
    padding: 16px;
  }
}

.post-text__content {
  font-size: 11cqw;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 9;
  -webkit-box-orient: vertical;
}

.post-text--detail .post-text__content {
  font-size: 6.5cqw;
  -webkit-line-clamp: 20;
}

.AMA {
  border-radius: 14.285% 14.285% 14.285% 57.142% / 10.667% 10.667% 10.667%
    42.667%;
  padding-top: 134%;
}

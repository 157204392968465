.wrapper {
  display: grid;
  gap: 16px;
}

@media (min-width: 640px) {
  .wrapper {
    grid-template-columns: 5fr 3fr;
  }
}

.post-details {
  display: grid;
  grid-template-rows: minmax(0, 1fr) auto;
  overflow: hidden;
}

.post-details__content {
  min-width: 0%;
  overflow: auto;
}

.post-details__body {
  font-size: 13px;
  margin-top: 10px;
}

@media (min-width: 940px) {
  .post-details__body {
    font-size: 14px;
  }
}

.section-heading {
  font-weight: 500;
  color: var(--primary-950);
}

.post-title {
  font-weight: 500;
  color: var(--primary-950);
}

.post-title--AMA {
  font-weight: 500;
  color: var(--primary-950);
  font-size: 22px;
}

@media (min-width: 640px) {
  .post-title--AMA {
    padding-right: 48px;
  }
}

.posted-by {
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.posted-by__cta {
  color: #0045ff;
  text-decoration: none;
  font-weight: 500;
}

.author {
  display: flex;
  align-items: center;
  gap: 8px;
}

.author__name {
  font-weight: 500;
  color: var(--primary-950);
}

.author__handle {
  font-weight: 500;
  color: var(--primary-500);
  font-size: 13px;
}

@media (min-width: 940px) {
  .author__handle {
    font-size: 14px;
  }
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
  padding: 16px 0;
}

.btn_round {
  border-radius: 48px;
  color: var(--white);
  background-color: var(--pink-600) !important;
  padding: 10px 20px;
}
.btn_round:hover {
  background-color: var(--pink-800) !important;
}

.latest {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 4px;
}

.latest__cta {
  color: #0045ff;
  text-decoration: none;
  font-weight: 500;
}

.latest__cta:hover {
  color: var(--primary-700);
}

@media (min-width: 640px) {
  .latest {
    gap: 6px;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 940px) {
  .latest {
    gap: 8px;
  }
}

.avatar {
  display: block;
  width: 100%;
  position: relative;
  z-index: 1;
  overflow: hidden;
  background-color: #cacaca;
  border-radius: 9999px;
  border: 2px solid #ffffff;
}

.avatar__img {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  object-position: center;
  z-index: 2;
}

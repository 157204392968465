.btn {
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  overflow: hidden;
  text-decoration: none;
  color: var(--pink-600);
  background-color: var(--pink-100);
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.btn:hover {
  background-color: var(--pink-200);
}

.btn > img {
  width: 16px;
  margin-right: 8px;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  backdrop-filter: blur(60px);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.modal {
  width: 100%;
  max-width: 568px; /* Same as share library */
  position: relative;
}

.close {
  color: #000;
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 100;
}

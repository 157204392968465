/* Variables */
:root {
  --primary-950: #030353;
  --primary-700: #3b3c87;
  --primary-500: #71739a;
  --primary-300: #a1a3b2;
  --primary-100: #edeeef;
  --blue-600: #0045ff;
  --blue-100: #e6ecff;
  --pink-800: #8f0e6a;
  --pink-600: #c21390;
  --pink-200: #f1bce2;
  --pink-100: #f9e7f4;
  --mauve-500: #5d598f;
  --mauve-800: #23204e;
  --gold: #e8bd6f;
  --white: #ffffff;
  --black: #000000;
}

html {
  /* font-family: Rubik, 'Segoe UI', Arial, sans-serif; Set in chakra styles.fonts.body */
  font-weight: 400;
  font-size: 14px;
  /* line-height: 1.333333; Set in chakra styles.global.body */
  /* color: var(--primary-500); Set in chakra styles.global.body */
}

@media (min-width: 640px) {
  html {
    font-size: 15px;
  }
}

@media (min-width: 940px) {
  html {
    font-size: 16px;
  }
}

p {
  margin-bottom: 16px !important;
}

.header {
  aspect-ratio: 2 / 1;
  overflow: hidden;
  position: relative;
  background-color: rgb(237, 238, 239);
  object-fit: cover;
  background-position: center center;
  background-size: cover;
  margin-bottom: 16px;
}

.owner {
  padding: 0;
  margin: 0;
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  position: absolute;
  bottom: 16px;
  left: 16px;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}
.owner-text {
  color: white;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  padding-bottom: 4px;
  margin-left: 8px;
}
.fade-text {
  opacity: 0.8;
}
.avatar {
  border-radius: 100px;
  width: 56px;
  height: 56px;
  border: 2px solid rgb(255, 255, 255);
}
.avatar__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100px;
}
.text-desc {
  color: #71739a;
  font-size: 13px;
  line-height: 18px;
  white-space: break-spaces;
}
.cta {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: row;
  margin-top: 16px;
}
.cta > :not(style) ~ :not(style) {
  margin-top: 0px;
  margin-left: 16px;
  margin-bottom: 0px;
}
.hr-light {
  border-top: 1px solid #edeeef;
  margin: 10px 0;
}
.centered {
  text-align: center;
}
.padded {
  padding: 32px;
}
/* h1 {
  color: #050179 !important;
  font-size: 28px !important;
  line-height: 34px !important;
  font-weight: 600 !important;
  margin-bottom: 10px !important;
} */
.p-mid {
  color: #71739a;
}
.icon-round {
  background-image: linear-gradient(
    45deg,
    rgba(194, 19, 144, 1) 20%,
    rgba(250, 192, 94, 1) 100%
  );
  display: inline-block;
  border-radius: 72px;
  padding: 0;
  margin: 0;
  height: 72px;
  width: 72px;
}
.icon-round svg {
  /*   width: 32px;
  height: 32px; */
  margin: 20px !important;
  margin: 0;
}
.page {
  width: 390px !important;
  margin: 0px auto !important; /* for demo only */
  background-color: white;
  padding: 16px;
  margin-bottom: 64px;
}
.title {
  font-size: 22px;
  line-height: 28px;
  font-weight: 600;
  margin-bottom: 16px;
}
.subs {
  width: 358px; /* for demo only */
  position: relative; /* required  for demo */
  /* height: 250px; /* some non-zero number */
  overflow: hidden; /* required */
  background-color: white;
  border: 1px solid #edeeef;
  border-radius: 16px;
  box-shadow: 0px 8px 16px 0px rgba(5, 1, 121, 0.12);
  margin-bottom: 16px;
  padding: 16px;
}

.ribbon {
  margin: 0;
  padding: 0;
  background: #a1a3b2;
  color: white;
  padding: 6px 0;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(42%) translateY(-20%) rotate(45deg);
  transform-origin: top left;
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}
.ribbon:before,
.ribbon:after {
  content: '';
  position: absolute;
  top: 0;
  margin: 0 -1px; /* tweak */
  width: 105%;
  height: 100%;
  background: #a1a3b2;
}
.ribbon:before {
  right: 100%;
}
.ribbon:after {
  left: 100%;
}
.subs-title {
  margin-bottom: 8px;
}
.discount {
  font-size: 28px;
  line-height: 34px;
  margin-bottom: 8px;
}
.price-orig {
  color: #a1a3b2;
  font-weight: 300;
  text-decoration: line-through;
}
.price {
  font-weight: 600;
}
.disc {
  color: #c21390;
  font-weight: 600;
}
.duration {
  font-size: 17px;
  line-height: 22px;
}
.feature {
  color: white;
  background-color: #d93aac;
  border-radius: 40px;
  margin-bottom: 8px;
  padding: 4px 16px;
  text-align: center;
}
.terms {
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;
}
.expiry {
  font-size: 11px;
  line-height: 13px;
  font-weight: 400;
}
.desc {
  color: #71739a;
  font-size: 13px;
  line-height: 18px;
}

.subs.full,
.subs.full .subs-title,
.subs.full .desc {
  color: #a1a3b2;
}
.subs.full .price-orig {
  color: #d2d3d9;
}
.subs.full .disc {
  color: #f1bce2;
}
.subs.full .feature {
  background-color: #f1bce2;
}
